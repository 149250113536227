import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

export const GlobalContext = createContext()

const GlobalContextProvider = ({ children }) => {
  const [sidebarContentsVisible, setSidebarContentsVisible] = useState(true)

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const store = {
    lastSidebarScrollTop: 0,
    lastSidebarColHeight: null,
    sidebarContentsVisible: {
      get: sidebarContentsVisible,
      set: setSidebarContentsVisible
    }
  }

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default GlobalContextProvider
