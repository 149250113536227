/* eslint-disable react/jsx-filename-extension, react/prop-types */
const React = require("react")
const GlobalContextProvider =
  require("./src/lib/context/globalContextProvider").default
const { MediaContextProvider } = require("./src/lib/media-queries")
const SuncoWidget = require("./src/components/sunco-widget/suncoWidget").default

exports.wrapRootElement = ({ element }) => (
  <MediaContextProvider>
    <GlobalContextProvider>{element}</GlobalContextProvider>
  </MediaContextProvider>
)

exports.wrapPageElement = ({ element }) => (
  <>
    {element}
    <SuncoWidget />
  </>
)
