import { createMedia } from "@artsy/fresnel"

const unitlessBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1364
}

export const breakpoints = Object.keys(unitlessBreakpoints).reduce(
  (acc, key) => {
    acc[key] = `${unitlessBreakpoints[key]}px`
    return acc
  },
  {}
)

const queryBreakpoints = createMedia({ breakpoints: unitlessBreakpoints })

export const mediaStyles = queryBreakpoints.createMediaStyle()

export const { Media, MediaContextProvider } = queryBreakpoints
