import { useEffect } from "react"

const errorEvent = "z2suncowidget.error"

const getWidgetButton = () => {
  const widget = document.getElementById("web-messenger-container")
  const widgetDoc =
    widget && (widget.contentDocument || widget.contentWindow.document)
  return widgetDoc && widgetDoc.getElementById("messenger-button")
}

const replaceWidgetButtonWithMailtoLink = () => {
  const widgetButton = getWidgetButton()

  if (widgetButton) {
    const buttonClone = widgetButton.cloneNode(true)
    widgetButton.parentNode.replaceChild(buttonClone, widgetButton)
    buttonClone.addEventListener("click", e => {
      e.preventDefault()
      window.open("mailto: serviceincident@zendesk.com")
    })
  }
}

const SuncoWidget = () => {
  useEffect(() => {
    document.addEventListener(errorEvent, replaceWidgetButtonWithMailtoLink)
    const script = document.createElement("script")
    if (window.location.hostname === "developer.zendesk.com") {
      script.src =
        "https://static.zdassets.com/z2-sunco-widget/z2-sunco-widget.js"
    } else {
      script.src =
        "https://static-staging.zdassets.com/z2-sunco-widget/z2-sunco-widget.js"
    }
    script.type = "text/javascript"
    script.defer = true
    script.onload = () => {
      // eslint-disable-next-line no-underscore-dangle
      window.__z2SunCoWidgetRef = new window.Z2SunCoWidget({
        inProduct: false,
        inShoppingCart: false
      })
    }
    document.body.appendChild(script)
  }, [])

  return null
}

export default SuncoWidget
